

.main_wrap {
    display: flex;
    flex-wrap: wrap;
}


.main-img-wrap {
    width:  45%;
    display: flex;
    justify-content: end;
    align-items: flex-end;
}

.smile-black {
    width: 72%;
    margin-right: 12%;
    object-fit: contain;
}

.main-text-wrap {
    max-width: 55%;
    display: flex;
    align-items: end;
    justify-content: end;
    text-align: justify;
}

.space-word {
    line-height: 30px;
}

.blue {
    color: #223f55;  
}

.text {
    width: 100%;
    height: 100%;
    padding: 40px;
    overflow:auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


.susisiekti_link {
    text-decoration: none;
    color: blue;
    font-weight: 700;
    font-size: 1.2rem;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.susisiekti_link:hover {
    color: rgb(32, 184, 184);
}


.text p {
    margin: 0;
}

main .text h1 {
   color: #223f55;
}

.dis {
    display: inline-block;
    width: 100%;
}

.mentorius-h {
    line-height: 1.7rem;
}

.men-span {
    font-size: 16px;
    font-weight: 400;
}

.b {
    font-weight: bold;
}

.daro p{
    margin: 0;
    padding: 0;
}

@media (max-width: 1730px) {

    .smile-black {
        width: 80%;
        margin-right: 10%;
    }

  }


  @media (max-width: 1480px) {

    .smile-black {
        width: 100%;
        margin: 5%;
        margin-right: 0;
    }
  }


@media (max-width: 1050px) {
    .main-text-wrap {
        max-width: 100%;
        display: flex;
        align-items: end;
        justify-content: end;
    }

    .main-img-wrap {
        width:  100%;
        display: block;
        padding: 5% 20%;
    }

    .smile-black {
        width: 100%;
        margin: 0;
    }

    .main-help-wrap {
        width:  100%;
        display: flex;
        justify-content: end;
    }

    .text {
        border-left: none;
        padding: 40px;
        overflow:auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;       
    }

  }



