.copy-r {
    text-align: center;
    color: white;
}

footer {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    background-color: #223f55;
}


.foot-links {
    display: flex;
    justify-content: center;
}

.foot-links a {
    padding: 10px;
    color: white;
   font-weight: 700;
   text-decoration: none;
}

.foot-links a:hover {
    color: aqua;
}


footer {
    margin-top: auto;
}

@media (max-width: 1400px) {
    footer {
        height: 100px;
    }
}
