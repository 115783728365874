.main-help-wrap {
    width: 100%;
    display: flex;
    margin-bottom: 10%;
}

.svg_div {
    width: 50px;
    font-size: 45px;
}

#svg1 {
    width: 70px;
}

.help {
    height: 100%;
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow:auto;
}

.how-help {
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
    align-self: center;
    color: #223f55;
    font-size: 50px;
}

.box4 {
    width: 100%;
    display: flex;
    text-align: center;
    padding: 0 10%;
}

.offers4 {
    display: flex;
    flex-wrap: wrap;
    width: 35%;
    justify-content: center;
    height: 300px;
    padding: 10px;
    align-items: end;
}

.offers4 h3 {
    font-size: 1.5em;
    margin: 0;
    color: #223f55;
}

.offers4 p {
    width: 100%;
    margin: 0;
}

.svg_div {
    width: 100%;
}

.more {
    padding: 10px 20px;
    background-color: #223f55;
    border: none;
    border-radius: 8px 8px;
    color: white;
    margin: 10px;
}

.more:hover {
    background-color: white;
    cursor: pointer;
    color: #223f55;
    font-weight: 600;
    outline: 2px solid #223f55;
}

@media (max-width: 1050px) {

    /* .main-img-wrap {
        width:  100%;
        display: flex;
        justify-content: end;
    } */

    .main-help-wrap {
        width:  100%;
        display: flex;
        justify-content: end;
    }

    .help {
        height: 100%;
        border-left: none;
    }


  }
  
  @media (max-width: 1050px) {
    .box4 {
        flex-wrap: wrap;
        justify-content: center;
    }

    .offers4{
        margin: 20px;
    }
  }

  @media (max-width: 515px) {
    .offers4 {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        height: 300px;
        padding: 10px;
        margin: 0 0 30px;
        align-items: end;
    }
  }

