
.contacts-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 10px 0;
}

.contacts {
    width: 50%;
    height: 70vh;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.map-container {
    height: 70vh;
    width: 50%;
}



.contact form {
    margin: 15px auto;
    width: 40%;
    height: 40%; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 30px;
    border-radius: 10px 10px;
}

label {
    font-weight: 700;
    font-size: 18px;
    margin: 5px;
}

textarea {
    resize: none;
    border-radius: 5px 4px;
    border: none;
    background-color: rgba(211, 211, 208, 0.452);
    padding: 5px;
    border: 2px solid #223f55;
  }

  form div select {
    background-color: rgba(211, 211, 208, 0.452);
    border-radius: 6px 6px;
    padding: 13px;

  }



  textarea:focus {
    outline: none;
  }

.contact form input {
    width: 35%;
    border-radius: 6px 6px;
    background-color: rgba(211, 211, 208, 0.452);
    padding: 5px;
    border: none;
    margin-bottom: 5px;

}

#contact-submit {

    padding: 5px;
    font-weight: 700;
    color: white;
    background-color: #223f55;
    border: 0;
    border-radius: 8px 8px;
    margin: 20px;
    width: 40%;
    padding: 20px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

#contact-submit1 {
    padding: 5px;
    font-weight: 700;
    color: white;
    background-color: rgba(128, 128, 128, 0.13);
    border: 0;
    border-radius: 8px 8px;
    margin: 20px;
    width: 20%;
    padding: 20px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

#contact-submit:hover {
    background-color:  white;
    cursor: pointer;
    color: #223f55;
    outline: 3px solid #223f55;
}

.response {
    height: 10px;
    font-size: 20px;
    color: green;
    font-weight: 700;
}

form {
    width: 100%;
    height: 100%;
    padding: 10px 20%;
    overflow:auto;
    display: flex;
    flex-wrap: wrap;
}

form div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
}

form div * {
    width: 100%;
    color: #223f55;
}

form div input {
    border: none;
    border-bottom: 2px solid #223f55;
    background-color: rgba(211, 211, 208, 0.452);
    padding: 10px;
}

form div input:focus {
    outline: none;
}

form div h1 {
    font-size: 50px;
    text-align: center;
}

.done {
    background-color: gray;
}

@media (max-width: 1050px) {

    form {
        width: 100%;
        height: 100%;
        border-left: none;
        padding: 50px 20%;
        overflow:auto;
        display: flex;
        flex-wrap: wrap;
    }

  }

  @media (max-width: 500px) {
    #contact-submit {
        width: 40%;
    }
  }

  @media (max-width: 440px) {
    .susisiekti {
        font-size: 2.3rem;
    }

    form {
        padding: 5px;
    }
  }