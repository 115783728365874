.EN-LT {
    position: absolute;
    top: 20px;
    right: 10px;
    font-size: 18px;
    font-weight: 700;
    margin: 5px;
    color: white;
}

.logo a {
    display: flex;
    justify-content: start;
}


.logo a img {
    width: 5rem;
}

nav {
    display: flex;
    justify-content: space-between;
    padding: 0 4%;
    height: 110px;
    align-items: center;
    background-color: #223f55;
}

.nav-link-wrap {
    display: flex;
}

.nav-link-wrap a {
    text-decoration: none;
    color: white;
    padding: 5px 10px;
    margin: 5px;
    align-self: center;
    font-weight: 700;
    border: 2px;
}

.nav-link-wrap a:hover {
    color: deepskyblue;
    border-color: deepskyblue;
}

.nav-link-wrap .curLink {
    border-bottom: 2px solid  white;
    font-size: 18px;
}

.link-main-name {
    text-decoration: none;
    color: black;
    
}

select {
    background-color: transparent;
    border: none;
}

select:hover {
    background-color: rgba(85, 82, 82, 0.247);
}

@media (max-width: 515px) {

    nav {
        justify-content: center;
    }

    .logo {
        display: none;
    }

    .nav-link-wrap {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 0 5%;
        flex-wrap: wrap;
    }


    .EN-LT {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 18px;
        font-weight: 700;
        margin: 5px;
        color: white;
    }

  }

  @media (max-width: 406px) {
    .nav-link-wrap a {
        width: 100%;
       text-align: center;
       margin: 5px;
    }

    .nav-link-wrap .curLink {
        border: none;
        color: aqua;
    }
  }